/**
 * @prettier
 */

// React Packages
import { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Components
import Header from '../../components/Header';
import SubmissionFlowStepper from '../../components/SubmissionFlowStepper';

import Navigator from '../../screens/Navigator';
import StageComplete from '../../screens/Messages/StageComplete';

import PreviewState from './components/PreviewState';
import PrepareToUploadState from './components/PrepareToUploadState';
import ErrorCaptureState from './components/ErrorCaptureState';
import ActiveModalOverlayState from './components/ActiveModalOverlayState';
import LoadingState from './components/LoadingState';

// Services
import {
    bytesToSize,
} from '../../services/shared/helpers';
import ApiService from '../../services/api/api';
import pdfCreationService from '../../services/shared/pdfCreationService';
import { PDFDocument } from 'pdf-lib';

// Actions
import { completeStage } from '../../screens/actions/submissionStateActions';

// Images
import poaModalImage from '../../assets/img/proof_address_modal.jpg';

// Config
import DataDogService from '../../services/shared/datadogService';
import logService from '../../services/shared/logService';

const ProofOfAddressPage = ({
    poaCompleted,
    completeStage,
    poaUploadKey,
    isDocSubmitted,
    docCompleted,
    title,
    subTitle,
    branding,

}) => {
    const [poaImage, setPoaImage] = useState(null);
    const [mimeType, setMimeType] = useState(null);
    const [dimensions, setDimensions] = useState({});
    const [isInvalidFileType, setIsInvalidFileType] = useState(false);
    const [isInvalidFileSize, setIsInvalidFileSize] = useState(false);
    const [isCaptureError, setIsCaptureError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showStageComplete, setShowStageComplete] = useState(false);
    const [errorRetryCount, setErrorRetryCount] = useState(3);
    const [isRetryLimitReached, setIsRetryLimitReached] = useState(false);
    const [navigation, setNavigation] = useState({
        action: 'load',
        props: null,
    });

    const brandingColor = `#${branding.data.company_branding.primary_color}`;
    const allowedFileTypes = [
        'image/jpeg',
        'image/png',
        'application/pdf',
    ];
    const pdfFileType = 'blob';
    const allowedUnits = ['Bytes', 'KB'];
    const maxFileSize = 10;
 
    const primaryFocusRef = useRef();

    // Draw captured image to canvas to retain image orientation.
    const drawToCanvas = image => {
        const hiddenCanvas = document.querySelector('#poaCanvas');
        hiddenCanvas.width = image.width;
        hiddenCanvas.height = image.height;

        const ctx = hiddenCanvas.getContext('2d');
        ctx.mozImageSmoothingEnabled = false;
        ctx.webkitImageSmoothingEnabled = false;
        ctx.msImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const canvasImage = hiddenCanvas.toDataURL('image/jpeg');
        return canvasImage;
    }

    const handleStartCamera = () => {
        document.getElementById('capturePOA').click();
        document
            .getElementById('capturePOA')
            .addEventListener('change', event =>
                handleCameraCapture(event)
            );
    }
    const handleCameraCapture = event => {        
        const poaInput = document.getElementById('capturePOA');
        if (poaInput.files && poaInput.files[0]) {
            try {
                // First get the mimeType for creating pdf, will always be jpg as it is drawn to the canvas.
                setMimeType('jpg');               
                const fileReader = new FileReader();
                fileReader.readAsDataURL(poaInput.files[0]);
                fileReader.addEventListener('load', function (event) {
                    const image = new Image();
                    image.src = event.target.result;
                    image.onload = function () {
                        setPoaImage(drawToCanvas(this));
                        setShowPreview(true);
                        // Set the dimensions for the pdf
                        setDimensions({
                            width: this.width,
                            height: this.height,
                        });                       
                    };
                });
                // Reset the input value
                poaInput.value = null;
            } catch (error) {
                poaInput.value = null;
                setIsCaptureError(true);                
                DataDogService.createError(error);
                logService.error(error);
            }
        } else {
            // Reset the input value
            poaInput.value = null;
        }
    }
   // Convert file to pdf
    const convertImageToPDF = (file, mimeType, dimensions) =>  {
        const { generatePdfFromImages } = pdfCreationService();
        return generatePdfFromImages(
            file,
            mimeType,
            pdfFileType,
            dimensions
        );
    }
    // Submit the proof of address document to the backend
    const postPoaFile = (file, poaUploadKey) => {
        // clearError(this);
        setIsLoading(true);     
        ApiService.uploadPoa(file, poaUploadKey)
            .then(response => {
                // Log success response to datadog
                DataDogService.log(
                    'Successfully send Proof of Address document.'
                );
                // Set valid document uploaded
                response?.data?.validKey &&
                setIsSubmitted(true);               
            })
            .catch(error => {
                // Handle errors
                DataDogService.createError(
                    'Unable to send Proof of Address document.'
                );
                logService.error(error);
                setIsLoading(false);
                setIsSubmitted(false);
                setNavigation({
                    action: 'error',
                    props: {
                        retryAction: () =>
                            postPoaFile(file, poaUploadKey),
                    },
                });
            });
    }

     // Submit the image after preview
    const handleSubmitCapturedPdf = () =>  {
        if (poaImage && mimeType) {
            const pdf = convertImageToPDF(
                poaImage,
                mimeType,
                dimensions
            );
            postPoaFile(pdf, poaUploadKey);
        } else {
            DataDogService.createError(
                'Error uploading proof of address file, no image captured'
            );
        }
    }
    // Reset for new capture attempt
    const  resetCaptureState = () => {
        setPoaImage(null);
        setShowPreview(false);
        setIsCaptureError(false);
    }

    // Retry capture/upload error
    const handleCaptureErrorRetry = () => {
        document
            .getElementById('capturePOA')
            .removeEventListener('change', handleCameraCapture);
        if (errorRetryCount <= 1) {
            setIsRetryLimitReached(true);
        } else {
            setErrorRetryCount(errorRetryCount - 1);
        }
        resetCaptureState();
    }

    // Retry Capture POA
    const handleRetryCapture = () => {
        document
            .getElementById('capturePOA')
            .removeEventListener('change', handleCameraCapture);
        resetCaptureState();
    }
     /**
     *
     * File upload POA starts here
     *
     **/

    // Select file
    const handleSelectFiles = () => {
        resetValidation();
        document.getElementById('uploadPOA').click();

        // Set change handler to fire only once to prevent multiple api calls.
        document
            .getElementById('uploadPOA')
            .addEventListener(
                'change',
                event => handleUploadPoaFile(event),
                { once: true }
            );
    }

    // Load image and convert to pdf
    const uploadImageAsPdf = file => {
        const mimeType = 'jpg';

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.addEventListener('load', function (event) {
            const image = new Image();
            image.src = event.target.result;
            image.onload = function () {
                // Convert to pdf
                const dimensions = {
                    width: this.width,
                    height: this.height,
                };
                const pdf = convertImageToPDF(
                    drawToCanvas(this),
                    mimeType,
                    dimensions
                );
                // Send file
                postPoaFile(pdf, poaUploadKey);
            };
        });
    }

    // Upload file and perform file validation
    const handleUploadPoaFile = event => {
        // Set loading at this stage.
        const poaInput = document.getElementById('uploadPOA');
        if (poaInput.files && poaInput.files[0]) {
            try {
                const file = poaInput.files[0];

                if (isValidFile(file)) {
                    if (file.type === 'application/pdf') {
                        // Read PDF file
                        const reader = new FileReader();
                        reader.readAsArrayBuffer(file);

                        // When reader is ready
                        reader.onload = async () => {
                            try {
                                // Load pdf into new pdf
                                const pdfDoc = await PDFDocument.load(
                                    reader.result,
                                    { ignoreEncryption: true }
                                );

                                // Save new pdf data
                                const pdfBytes = await pdfDoc.save({
                                    useObjectStreams: false,
                                });

                                // Build pdf file
                                const bytes = await new Uint8Array(pdfBytes);
                                const blob = await new Blob([bytes], {
                                    type: 'application/pdf',
                                });

                                // Send new pdf file
                                postPoaFile(blob, poaUploadKey);
                            } catch (err) {
                                // If building fails, send original file
                                postPoaFile(file, poaUploadKey);
                            }
                        };
                    } else {
                        uploadImageAsPdf(file);
                    }
                }

                // Set invalid file size modal
                if (!isValidFileSize(file.size)) {
                    setIsInvalidFileSize(true);
                }

                // Set invalid file type modal
                if (!isValidFileType(file.type)) {
                    setIsInvalidFileType(true);
                }
            } catch (error) {
                poaInput.value = null;
                setIsCaptureError(true);
                DataDogService.createError(error);
                logService.error(error);
            }
            poaInput.value = null;
        } else {
            poaInput.value = null;
        }
    }

    /**
     *
     * File validation starts here
     *
     **/

    // Check Valid file
    const isValidFile = file => isValidFileType(file.type) && isValidFileSize(file.size);
        

    // Check valid file type
    const isValidFileType = fileType => allowedFileTypes.includes(fileType);    

    // Check valid file size
    const isValidFileSize = fileSize =>  {
        const fileSizeData = bytesToSize(fileSize);

        if (allowedUnits.includes(fileSizeData.unit)) {
            return true;
        }

        if (
            fileSizeData.unit === 'MB' &&
            fileSizeData.size <= maxFileSize
        ) {
            return true;
        }

        return false;
    }

    // Reset validation state
    const resetValidation = () => {
        setIsInvalidFileSize(false);
        setIsInvalidFileType(false);
        setShowModal(false);
    }
     // Show modal if ID is already submitted
    const  showDocumentUploadModal = () =>  {
        if (isDocSubmitted && docCompleted) {
            return true;
        }
        return false;
    }
    useEffect(() => {
        // Sets focus to primary heading on first render
        if (primaryFocusRef && primaryFocusRef.current) {
            primaryFocusRef.current.focus();
        }
        if (poaCompleted) {
            setShowStageComplete(true);
        }
        // Sets document title
        document.title = 'Proof of Address - Upload';
    },[poaCompleted]);

    useEffect(() => {
        // Sets focus to primary heading on first render
        if (primaryFocusRef && primaryFocusRef.current) {
            primaryFocusRef.current.focus();
        }

        // Sets modal for invalid file size or  invalid file type
        if (isInvalidFileSize || isInvalidFileType) {
            setShowModal(true);
        }
    },[isInvalidFileSize,isInvalidFileType]);

    useEffect(() => {
        // Continue once pdf is submitted.        
        if(isSubmitted){
            completeStage('poa_upload');
            setNavigation({
                action: 'next',
            });
        }
        return () => {
            // Remove all event listers
            const capturePOA = document.getElementById('capturePOA');
            const uploadPOA = document.getElementById('uploadPOA');
        
            if (capturePOA) {
                capturePOA.removeEventListener('change', handleCameraCapture);
            }
        
            if (uploadPOA) {
                uploadPOA.removeEventListener('change', handleUploadPoaFile);
            }
        }
    },[isSubmitted]);

    // Document upload is already completed
    if (
        showStageComplete &&
        navigation.action === 'load'
    ) {        
        return (
            <div className='root-layout-container proof-of-address-page'>
                <Header />
                <StageComplete
                    continue={() =>
                        setNavigation({ action: 'next' })
                    }
                    message='idpal_poa_captured'
                />
                <Navigator
                    page={'proof_of_address'}
                    action={navigation.action}
                    propsToPass={navigation.props}
                />
            </div>
        );
    }

    return (
        <div className='root-layout-container proof-of-address-page'>           
            <Header />
            <SubmissionFlowStepper />
            {isLoading? <LoadingState/>: (    
                <>         
                    {/* Show instructions state */}
                    {!showPreview && !isCaptureError && (
                        <PrepareToUploadState
                            primaryFocusRef={primaryFocusRef}
                            title={title}
                            subTitle={subTitle}                           
                            handleStartCamera={handleStartCamera}
                            handleSelectFiles={handleSelectFiles}
                            brandingColor={brandingColor}
                        />
                    )}

                    {showPreview && (
                        <PreviewState
                            primaryFocusRef={primaryFocusRef}
                            poaImage={poaImage}
                            handleSubmitCapturedPdf={handleSubmitCapturedPdf}
                            handleRetryCapture={handleRetryCapture}                                                      
                        />
                    )}

                    {isCaptureError && (
                      <ErrorCaptureState
                        primaryFocusRef={primaryFocusRef}
                        handleCaptureErrorRetry={handleCaptureErrorRetry}
                        isRetryLimitReached={isRetryLimitReached}
                      />
                    )}
                </>
            )}
            <ActiveModalOverlayState
                showDocumentUploadModal={showDocumentUploadModal}
                poaModalImage={poaModalImage}
                isInvalidFileSize={isInvalidFileSize}
                isInvalidFileType={isInvalidFileType}
                showModal={showModal}
            />
            <input
                className='hidden'
                type='file'
                id='capturePOA'
                accept='image/*'
                capture
                onClick={handleCameraCapture}
            />
            <input
                className='hidden'
                type='file'
                id='uploadPOA'
                accept='.png,.jpg,.jpeg,.pdf'
                onClick={handleUploadPoaFile}
            />
            <canvas className='hidden' id='poaCanvas'></canvas>
            <Navigator
                page={'proof_of_address'}
                action={navigation.action}
                propsToPass={navigation.props}
            />
        </div>
    );

};

function mapStateToProps(state) {
    return {
        title: state.config.profile.data.proof_of_address[0].title,
        subTitle: state.config.profile.data.proof_of_address[0].sub_title,     
        poaUploadKey: state.config.profile.data.poa_upload_key,
        docCompleted:
            state.submissionState.submissionState.document_upload.completed,
        isDocSubmitted: state.config.isDocSubmitted,
        poaCompleted:
            state.submissionState.submissionState.poa_upload.completed,      
        branding: state.config.profile,       
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ completeStage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ProofOfAddressPage);
