import InstructionModal from '../../../screens/Messages/InstructionModal';
import { useTranslation } from 'react-i18next';

const ActiveModalOverlayState = ({
    showDocumentUploadModal, 
    poaModalImage, 
    isInvalidFileSize,
    isInvalidFileType,
    showModal,
}) => {
    const { t } = useTranslation();
    return (
        <>  
            {
                showDocumentUploadModal() && (
                <InstructionModal
                    heading={t('idpal_document_upload_completed')}
                    message={t(
                        'idpal_document_upload_completed_poa_message'
                    )}
                    image={poaModalImage}
                    cta={t('idpal_continue')}
                    showCta
                />
            )}
        
            {showModal && isInvalidFileSize && (
                <InstructionModal
                    heading={t('idpal_file_too_large')}
                    message={t('idpal_file_too_large_message')}
                    showCta
                />
            )}
        
            {showModal && isInvalidFileType && (
                <InstructionModal
                    heading={t('idpal_file_wrong_type')}
                    message={t('idpal_file_wrong_type_message')}
                    showCta
                />
            )}
        </>
    )
};
export default ActiveModalOverlayState;